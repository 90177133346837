
import TTView from '@/components/ui/TTView.vue';

export default {
  name: 'AppInfo',
  components: {
    TTView,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      href: 'https://potok.io/survey/?utm_source=estimate_completing&utm_content=product_360',
    };
  },
};
