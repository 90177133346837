
import Vue from 'vue';

export default Vue.extend({
  name: 'TTView',

  props: {
    offset: {
      type: Boolean,
      default: false,
    },
    noGutters: {
      type: Boolean,
      default: false,
    },
  },
});
